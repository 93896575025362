import React, { useState } from "react";
import classnames from "classnames";

import { Block, EventsGrid, TournamentBracket, ReactBracket, TournamentHeat } from "..";

import "./tournament-events.scss";

const TournamentEvent = ({ data, length }) => {
  const { currentEvent, title, tournamentState, upcomingEvents } = data || {};
  /**
   *
   * Default status is starting with current tournament active.
   *
   */
  const [active, setActive] = useState("current");

  /**
   * Updates active tab.
   * @param {string} newActive
   */
  const handleToggleClick = (newActive) => {
    if (newActive !== active) {
      setActive(newActive);
    }
  };

  return (
    <Block className="events-block tournament-events">
      <nav className="tournament-events__nav">
        <button
          className={classnames("tournament-events__toggle-btn", {
            "tournament-events__toggle-btn--active": active === "current",
          })}
          onClick={() => handleToggleClick("current")}
        >
          Current event
        </button>
        <button
          className={classnames("tournament-events__toggle-btn", {
            "tournament-events__toggle-btn--active": active === "upcoming",
          })}
          onClick={() => handleToggleClick("upcoming")}
        >
          Upcoming events
        </button>
      </nav>
      <div className="tournament-events__content">
        {tournamentState && (
          <span className="tournament-events__tournament-state">
            {tournamentState}
          </span>
        )}
        {title && <h2 className="events-block__title">{title}</h2>}
        {active === "current" && <TournamentBracket data={currentEvent} />}
        {active === "upcoming" && <EventsGrid events={upcomingEvents} length={length}/>}
      </div>
    </Block>
  );
};

export default TournamentEvent;